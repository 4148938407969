import { t } from "@/i18n-js/instance";
import { useImagePostModal } from "@/react/components/Modals/ImagePostModal";
import { Icon } from "@circle-react-shared/Icon";

interface OpenImageModalButtonProps {
  spaceId?: number;
}
export const OpenImageModalButton = ({
  spaceId,
}: OpenImageModalButtonProps) => {
  const imagePostModal = useImagePostModal();
  return (
    <button
      type="button"
      className="text-dark flex w-full justify-center p-5"
      onClick={() => {
        void imagePostModal.show({
          spaceId,
          onClose: () => {
            imagePostModal.remove();
          },
        });
      }}
    >
      <Icon
        aria-label={t("mobile_nav.quick_post")}
        type="bar-plus"
        size={24}
        useWithFillCurrentColor
      />
    </button>
  );
};
