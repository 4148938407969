import { useEffect } from "react";
import classnames from "classnames";
import { useHistory, useParams } from "react-router-dom";
import { isEventsSpace } from "@/react/helpers/spaceHelpers";
import { spaces } from "@/react/helpers/urlHelpers";
import { usePostResource } from "@/react/hooks/posts/usePostResource";
import { useIsLoadingAppData } from "@/react/hooks/useIsLoadingAppData";
import { useEditPostV2Modal } from "@circle-react/components/QuickPostV2/EditPostModal";
import { usePunditUserContext, useSpacesContext } from "@circle-react/contexts";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { useOnLoadInEditMode } from "./useOnLoadInEditMode";

interface Params {
  postSlug?: string;
  spaceSlug?: string;
}

export const useShowPost = () => {
  const { postSlug, spaceSlug } = useParams<Params>();
  const { isLoading: isLoadingPunditUser } = usePunditUserContext();
  const spacesContext = useSpacesContext();
  const history = useHistory();
  const { show: showEditPostV2Modal } = useEditPostV2Modal();
  const space = spacesContext.helpers.findBy({ slug: spaceSlug });

  const postResource = usePostResource({
    spaceId: space?.id,
    spaceSlug,
    postSlug,
  });

  const isLoadingAppData = useIsLoadingAppData();
  const isIframeEmbed = useIsIframeEmbed();
  const hasRightSidebar =
    Boolean(space?.hide_right_sidebar) && !isEventsSpace(space);

  const [shouldShowEditPostModal, setShouldShowEditPostModal] =
    useBoolean(false);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant" as ScrollBehavior,
    });
  }, [postResource?.post?.id]);

  const bodyClassNames = classnames(
    `view-space view-space--${postResource.data?.space_id} view-space-in-group view-space-in-group--${space?.space_group_id}`,
  );

  const isLoading =
    isLoadingAppData || postResource.isLoading || !postResource.data;

  useOnLoadInEditMode({
    enabled: !isLoadingPunditUser && !isLoading,
    key: `${spaceSlug}${postSlug}`,
    onLoadInEditMode: async () => {
      await showEditPostV2Modal({
        post: postResource.data,
        onPostChange: postResource.onPostChange,
      });
    },
  });

  const onPostDestroy = () => {
    if (!spaceSlug) return;
    history.push(spaces.show({ spaceSlug }));
  };

  const perPagePostIds = postResource.data ? [[postResource.data.id]] : [];

  return {
    isLoading,
    isLoadingAppData,
    isIframeEmbed,
    space,
    postResource,
    bodyClassNames,
    hasRightSidebar,
    shouldShowEditPostModal,
    setShouldShowEditPostModal,
    perPagePostIds,
    onPostDestroy,
    spacesContext,
    spaceSlug,
    postSlug,
  };
};
