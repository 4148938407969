import { useRouteMatch } from "react-router-dom";
import { useSpacesContext } from "@circle-react/contexts";
import {
  isEventsSpace,
  isImagesSpace,
} from "@circle-react/helpers/spaceHelpers";
import { NewPostLinkButton } from "./NewPostLinkButton";
import { OpenEventModalButton } from "./OpenEventModalButton";
import { OpenImageModalButton } from "./OpenImageModalButton";
import { OpenQPModalButton } from "./OpenQPModalButton";

export interface NewPostLinkProps {
  newPostUrl: string;
  newPostUseDataRemote?: boolean;
  shouldNewPostOpenModal?: boolean;
}

export const NewPostLink = ({
  newPostUrl,
  newPostUseDataRemote = false,
  shouldNewPostOpenModal = false,
}: NewPostLinkProps) => {
  const { params: { spaceSlug } = { spaceSlug: "" } } =
    useRouteMatch<{ spaceSlug: string }>("/c/:spaceSlug") || {};
  const space = useSpacesContext().helpers.findBy({ slug: spaceSlug });

  if (isEventsSpace(space)) {
    return <OpenEventModalButton />;
  }

  if (isImagesSpace(space)) {
    return <OpenImageModalButton spaceId={space?.id} />;
  }

  if (shouldNewPostOpenModal) {
    return <OpenQPModalButton />;
  }

  return (
    <NewPostLinkButton
      newPostUrl={newPostUrl}
      newPostUseDataRemote={newPostUseDataRemote}
    />
  );
};
