import { useMutation } from "react-query";
import * as yup from "yup";
import I18n from "@/i18n-js/instance";
import type { ApiError } from "@/react/config/CustomErrors";
import { useAddLinkModal } from "@circle-react/components/Modals/AddLinkModal";
import { Modal } from "@circle-react/components/shared/uikit/ModalV2";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  reactQueryPost,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";

const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required(I18n.t("link_name_required")),
  url: yup.lazy(value => {
    if (value.startsWith("mailto:")) {
      return yup
        .string()
        .trim()
        .matches(
          /([mailto:\w.\-pL]+@\w+.[\w.\-pL]+)/,
          I18n.t("link_url_invalid"),
        )
        .required(I18n.t("link_url_required"));
    }
    return yup
      .string()
      .url(I18n.t("link_url_invalid"))
      .required(I18n.t("link_url_required"));
  }),
});

export interface AddLinkFormProps {
  isEdit?: boolean;
  link?: any;
}

export const AddLinkForm = ({ isEdit, link }: AddLinkFormProps) => {
  const { refetch: refetchLinks } = usePunditUserContext();
  const addLinkModal = useAddLinkModal();

  const afterCreateOrUpdate = () => {
    void refetchLinks();
    void addLinkModal.hide();
  };

  const { mutate: createLink, isLoading: isSubmitting } = useMutation<
    unknown,
    ApiError,
    any
  >(
    data =>
      reactQueryPost(internalApi.communities.communityLinks.create(), data),
    {
      onSuccess: afterCreateOrUpdate,
    },
  );

  const { mutate: updateLink, isLoading: isUpdating } = useMutation<
    unknown,
    ApiError,
    any
  >(
    data =>
      reactQueryPut(
        internalApi.communities.communityLinks.update({
          communityLinkId: link.id,
        }),
        data,
      ),
    {
      onSuccess: afterCreateOrUpdate,
    },
  );

  return (
    <Form
      onSubmit={data => {
        isEdit ? updateLink(data) : createLink(data);
      }}
      validationSchema={VALIDATION_SCHEMA}
      defaultValues={
        isEdit
          ? { ...link }
          : {
              name: "",
              url: "",
            }
      }
    >
      <Modal.Body>
        <Form.Item
          name="name"
          variant="extra-bottom-margin"
          label={I18n.t("link_name")}
          description={I18n.t("link_name_description")}
          hideBorder
        >
          <Form.Input />
        </Form.Item>
        <Form.Item
          variant="extra-bottom-margin"
          name="url"
          label={I18n.t("link_url")}
          description={I18n.t("link_url_description")}
          hideBorder
        >
          <Form.Input />
        </Form.Item>
      </Modal.Body>

      <Modal.Footer>
        {isEdit ? (
          <Button full disabled={isUpdating} type="submit" variant="primary">
            {isUpdating ? I18n.t("link_updating") : I18n.t("link_update")}
          </Button>
        ) : (
          <Button full disabled={isSubmitting} type="submit" variant="primary">
            {isSubmitting ? I18n.t("link_creating") : I18n.t("link_create")}
          </Button>
        )}
      </Modal.Footer>
    </Form>
  );
};
