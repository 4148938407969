import { useEffect, useState } from "react";
import { arrayMoveImmutable as arrayMove } from "array-move";
import { useIsLoadingAppData } from "@/react/hooks/useIsLoadingAppData";
import {
  usePunditUserContext,
  useSpaceGroupsContext,
  useSpacesContext,
} from "@circle-react/contexts";
import { fetchRequest } from "@circle-react/helpers/backendRequestHelpers";
import {
  canCreateSpaces,
  isCollapsed,
} from "@circle-react/helpers/spaceGroupHelpers";
import { isSpaceVisibleOnSidebar } from "@circle-react/helpers/spaceHelpers";
import { useSpaceSidebarNotifications } from "@circle-react/hooks/useSpaceSidebarNotifications";
import type { Space, SpaceGroup } from "@circle-react/types";
import { CommunitySidebarSkeleton } from "@circle-react-uikit/SkeletonLoader/skeletons/CommunitySidebarV2";
import { SidebarV2 } from "../SidebarV2";
import "./styles.scss";

export interface SpaceGroupWithSpaces extends SpaceGroup {
  collapsed: boolean;
  spaces: Space[];
}

export type SidebarSpaceGroups = SpaceGroupWithSpaces[];

export interface SpaceGroupDragProperties {
  index: number | null;
  isDragging: boolean;
}

export const SideBar = () => {
  const [spaceGroupDragProperties, setSpaceGroupDragProperties] =
    useState<SpaceGroupDragProperties>({
      index: null,
      isDragging: false,
    });

  const {
    helpers: { isDataLoading: isSpaceGroupsDataLoading },
    records: spaceGroups,
    actions: { refresh: refreshSpaceGroups },
  } = useSpaceGroupsContext();

  const {
    helpers: { isDataLoading: isSpacesDataLoading, spaceVisibleOnSidebar },
    actions: { refresh: refreshSpaces },
  } = useSpacesContext();

  const { currentCommunityMember, isLoading: isCommunityLoading } =
    usePunditUserContext();

  const [spaceGroupWithSpaces, setSpaceGroupWithSpaces] =
    useState<SidebarSpaceGroups>([]);

  const spaces = spaceVisibleOnSidebar();
  const isLoading =
    isSpacesDataLoading() || isSpaceGroupsDataLoading() || isCommunityLoading;

  const { refetchSpaceNotifications } = useSpaceSidebarNotifications();

  const handleUpdateBeforeSortStart = (dragProperties: any) => {
    setSpaceGroupDragProperties(prev => ({
      ...prev,
      index: dragProperties.index,
      isDragging: true,
    }));
  };

  useEffect(() => {
    currentCommunityMember && void refetchSpaceNotifications();
  }, [currentCommunityMember, refetchSpaceNotifications]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const allSpaceGroupsWithSpaces = spaceGroups.map(
      (spaceGroup: SpaceGroup): SpaceGroupWithSpaces => {
        const filteredSpaces = spaces.filter(
          (space: Space) =>
            space.space_group_id === spaceGroup.id &&
            isSpaceVisibleOnSidebar({ space, spaceGroup }),
        );

        return {
          ...spaceGroup,
          collapsed: isCollapsed({
            currentCommunityMember,
            spaceGroupId: spaceGroup.id,
          }),
          spaces: filteredSpaces,
        };
      },
    );

    // We're only showing space groups that have spaces or where the member can
    // interact with the space group (create spaces or join spaces).
    const visibleSpaceGroupsWithSpaces = allSpaceGroupsWithSpaces.filter(
      (spaceGroup: SpaceGroupWithSpaces) =>
        spaceGroup.spaces.length > 0 ||
        spaceGroup.hide_non_member_spaces_from_sidebar ||
        canCreateSpaces({ spaceGroup }),
    );

    setSpaceGroupWithSpaces(visibleSpaceGroupsWithSpaces);
  }, [isLoading, spaces, spaceGroups, currentCommunityMember]);

  const applySpaceReorder = async ({ spaceGroupId, orderedSpaceIds }: any) => {
    try {
      await fetchRequest(`/space_groups/${spaceGroupId}/reorder_spaces`, {
        method: "POST",
        body: JSON.stringify({
          order_array: orderedSpaceIds,
        }),
      });
      await refreshSpaces();
    } catch (err) {
      console.error("Error while reordering space: ", err);
    }
  };

  const onSpaceSortEnd = ({ oldIndex, newIndex, spaceGroup }: any) => {
    const reorderedData = spaceGroupWithSpaces.map(currentGroup => {
      if (currentGroup.id == spaceGroup.id) {
        const spaces: Space[] = arrayMove(
          spaceGroup.spaces,
          oldIndex,
          newIndex,
        );

        return { ...currentGroup, spaces };
      }

      return currentGroup;
    });

    setSpaceGroupWithSpaces(reorderedData);

    const orderedSpaceIds = reorderedData
      .find(currentGroup => currentGroup.id === spaceGroup.id)
      ?.spaces.map(space => space.id);

    void applySpaceReorder({ spaceGroupId: spaceGroup.id, orderedSpaceIds });
  };

  const applySpaceGroupReorder = async (orderedSpaceGroupIds: any) => {
    try {
      await fetchRequest("/space_groups/reorder", {
        method: "POST",
        body: JSON.stringify({
          order_array: orderedSpaceGroupIds,
        }),
      });
      setSpaceGroupDragProperties({
        index: null,
        isDragging: false,
      });
      refreshSpaceGroups();
    } catch (err) {
      console.error("Error while reordering space groups: ", err);
    }
  };

  const onSpaceGroupSortEnd = ({ oldIndex, newIndex }: any) => {
    const reorderedData = arrayMove(spaceGroupWithSpaces, oldIndex, newIndex);
    setSpaceGroupWithSpaces(reorderedData);

    void applySpaceGroupReorder(
      reorderedData.map(currentGroup => currentGroup.id.toString()),
    );
  };
  const isLoadingAppData = useIsLoadingAppData();

  if (isLoading || isLoadingAppData) {
    return <CommunitySidebarSkeleton />;
  }

  return (
    <SidebarV2
      onSortEnd={onSpaceGroupSortEnd}
      spaceGroupWithSpaces={spaceGroupWithSpaces}
      onSpaceSortEnd={onSpaceSortEnd}
      spaceGroupDragProperties={spaceGroupDragProperties}
      updateBeforeSortStart={handleUpdateBeforeSortStart}
    />
  );
};
