import { useRouteMatch } from "react-router-dom";

interface SpaceSlugParams {
  spaceSlug?: string;
}

export const useSpaceSlugFromUrl = () => {
  const match = useRouteMatch<SpaceSlugParams>("/c/:spaceSlug");
  return match?.params?.spaceSlug;
};
